import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from '@clients/clients.service';
import { ChoizMessagesService } from '@shared/components/choiz-messages/choiz-messages.service';
import { Subject, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { xImageMyAccount } from '@core/interceptors/auth.interceptor';
import { AuxiliaresService } from '@core/services/auxiliares.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsDetailService {

  private http = inject(HttpClient);
  private fb = inject(FormBuilder);
  private _clients = inject(ClientsService);
  private _cms = inject(ChoizMessagesService);
  private sanitizer = inject(DomSanitizer);
  private _auxiliares = inject(AuxiliaresService);

  sex: any = [];
  gender: any = [];

  public loadedData = signal(false);
  loadedDataTreatments: boolean = false;

  selectedClient = signal<any>(undefined);
  selectedClientDetail = signal<any>(undefined);

  treatments: any = [];

  frecuenciesTreatment1: any;
  frecuenciesTreatment2: any;


  public cards: any = [];
  public details: any = [];
  public formClient: FormGroup = new FormGroup({});
  public formClientTreatments: FormGroup = new FormGroup({});
  public prospectId: string = '';

  private subscriptions: Subscription = new Subscription;
  private unsubscribe$ = new Subject<void>();

  blockChars: RegExp = /[$!"#%&/=?+*-]/g;

  getData(){
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getJsonDefinition(),
      ]).then(resp => {
        resolve(resp)
      }).catch(e => reject(e))
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  caracteresProhibidosValidator() {
    return (control) => {
      const valor = control.value;
      if (/[\$!"#%&\/=?+*-]/.test(valor)) {
        return { caracteresProhibidos: true };
      }
      return null;
    };
  }

  async createDefinition(){
    this.generateStructure();
  }

  async getJsonDefinition() {
    return new Promise(async (resolve, reject) => {
        this.http.get(`assets/data/detailsClients.json`).subscribe(async (defClients: any) => {
        this.details = await defClients.CLIENTS;
        resolve(true);
      })
    })

  }

  generateStructure(){
    this.cards = this.details.cards;
    this.generateForm();
    this.cards.forEach((card, i) => {
      this.getOptions(card);
    })
  }

  async generateForm(){
    await this.generateFormClient();
    await this.setDataFormClient();
  }

  async generateFormClient(){
    this.formClient = this.fb.group({
      client: this.fb.group({
        name: [ {value: '', disabled: true}, [] ],
        lastName: [ {value: '', disabled: true}, [] ],
        prospectId: [ {value: '', disabled: true}, [] ],
        dni: [ {value: '', disabled: true}, [] ],
        birthSex: [ {value: '', disabled: true}, [] ],
        selfPerceivedGender: [ {value: '', disabled: true}, [] ],
        email: [ {value: '', disabled: true}, [] ],
        telephone: [ {value: '', disabled: true}, [] ],
        street: [ {value: '', disabled: true}, [Validators.required, Validators.maxLength(50), this.caracteresProhibidosValidator()] ],
        streetNumber: [ {value: '', disabled: true}, [Validators.required, Validators.maxLength(5)] ],
        apartment: [ {value: '', disabled: true}, [] ],
        apartmentNumber: [ {value: '', disabled: true}, [Validators.maxLength(4), this.caracteresProhibidosValidator()] ],
        additionalInformation: [ {value: '', disabled: true}, [Validators.maxLength(30)] ],
        colony: [ {value: '', disabled: true}, [Validators.required] ],
        zipCode: [ {value: '', disabled: true}, [Validators.required] ],
        city: [ {value: '', disabled: true}, [Validators.required] ],
        province: [ {value: '', disabled: true}, [Validators.required] ],
        healthInsurancePlan: [ {value: '', disabled: true}, [] ],
        healthInsuranceNumber: [ {value: '', disabled: true}, [] ],
      })
    })
  }

  setEnabledForm(){
    this.formClient.enable();
  }
  setDisabledForm(){
    this.formClient.disable();
  }

  getProspectId(){
    const id = this.selectedClient().id;
    this._clients.getProspectId(id).subscribe(
        (next) => {
          this.formClient.get('client').get('prospectId').patchValue(next['prospect_id']);
          this.prospectId = next['prospect_id'];
        },
        (err) => {

        }
    )
  }

  setSaveFormUser(){
    this._clients.updateClient(this.formClient.value, this.selectedClient()).subscribe(resp => {
      if(resp){
        this._cms.showMessage({ type: 'success', message: 'Actualizado correctamente', title: 'Actualizado', whatsapp: false, autoClose: true });
      }
    })

  }

  getOptions(card) {
    setTimeout(() => {
      try{
        card.groups.forEach(group => {
              group.rowCol.forEach(rowcol => {
                  rowcol.controls.forEach(control => {

                    if(control.type == 'DROPDOWN'){
                      switch(control.name){
                        case 'birthSex':
                          control.options = this._auxiliares.sexs();
                          break;
                        case 'selfPerceivedGender':
                          control.options = this._auxiliares.genders();
                          break;
                        case 'frecuencies':
                          this.frecuenciesTreatment1 = this.selectedClientDetail[0].treatment.frequencies;
                          if(this.selectedClientDetail.length > 1){
                            this.frecuenciesTreatment2 = this.selectedClientDetail[1].treatment.frequencies;
                          }
                          control.options = this.frecuenciesTreatment1;
                          break;
                      }
                    }
                })
              })
          })
        } catch(e){

        }
      }, 1500)
  }

  async setDataFormClient(){
    if(this.selectedClient()){
      this.formClient.get('client').reset(this.selectedClient());
      this.loadedData.set(true);
    }
  }

  setEnableTreatment(dataDetail){
    this._clients.enableSuscription(this.selectedClient().id, dataDetail.treatment.id).subscribe();
  }
  setDisableTreatment(dataDetail, respuestas){
    this._clients.disableSuscription(this.selectedClient().id, dataDetail.treatment.id, respuestas.data.resp).subscribe();
  }

  getImageFile(fileName:string){
  	const chatEndpoint = environment.apiUrl;
		const chatFile = "resource";

		const headers = new HttpHeaders({
			'file-name': fileName,
			'bucket-folder-name': 'my-account/customer'
		})

		return this.http.get(`${chatEndpoint}/${chatFile}`, {
			headers,
			responseType: 'blob',
			context: xImageMyAccount()
		}).pipe(
			image => { return image }
		)
  }

  // Imagenes
  async showImage(e, path: any, _data: any){
    e.preventDefault();
    let image: any;
    let fileName: any;
    await this.getImageFile(path).subscribe((data: any) => {
        let blob = new Blob([data], { type: "image/png" });
        let objectURL = URL.createObjectURL(blob);
        image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        fileName = `${_data['data']['name'].toLowerCase()}_${_data['data']['last_name'].toLowerCase()}_${path.toLowerCase()}`;
      })
      return {image, path, fileName}
  }

}
